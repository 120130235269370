import http from '../utils/http';
//根据pid获取使用指南列表(一级指南数据pid为0)
export function guideList(data) {
  return http({
    url: '/api/official/operatingGuide/list',
    method: 'get',
    params: data
  })
}


export function getOperatingGuideById(data) {
  return http({
    url: '/api/official/operatingGuide/getOperatingGuideById',
    method: 'get',
    params: data
  })
}

//根据pid获取常见问题列表(一级指南数据pid为0)
export function problemList(data) {
  return http({
    url: '/api/official/commonProblem/list',
    method: 'get',
    params: data
  })
}