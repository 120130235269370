<template>
  <div class="cell-box">
    <van-cell
      v-for="(item, index) in list"
      :title="item.title"
      is-link
      :to="'guideDetail?pid=' + item.id"
      :key="index"
    >
      <template>
        <div v-html="item.img" class="custom-title"></div>
      </template>
    </van-cell>
  </div>
</template>
<script>
import { Cell } from "vant";
import { guideList } from "../api/guide";
import { wxPublicAuth } from "../api/home";
export default {
  name: "guideList",
  components: {
    "van-cell": Cell,
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    console.log(this.$route.query.pid);
    guideList({
      pid: this.$route.query.pid,
      pageNum: 1,
      pageSize: 1000,
    }).then((res) => {
      console.log(res);
      if (res.code == 200) {
        this.list = res.data.list;
        for (var i = 0; i < res.data.list.length; i++) {
          var imgReg = /<img.*?(?:>|\/>)/gi;
          var arr = res.data.list[i].content.match(imgReg);
          if (arr) {
            res.data.list[i].img = arr[0].replace(/<img/g,"<img style='max-width:100px;height:auto;'"
            )
          }
        }
      } else {
      }
    });

    

  },
  methods: {
      wxPublicAuth() {
      wxPublicAuth({
        page: "home",
      })
        .then((res) => {
          console.log(res);

          window.location.href = res.data;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    
  },
};
</script>
<style>
.van-cell__title {
  display: flex;
  align-items: center;
}
.cell-box{
   padding: 0 1.8rem;
}
.van-icon{
  font-size: 0;
}
.van-cell{
  padding: 0;
}
</style>


